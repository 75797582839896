import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

import Icon from '../Icon';
import * as subscribeService from '../../apis/subscribe';

const Wrapper = styled.div`
  font-size: 1rem;

  @media screen and (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

const Title = styled.p`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1b2733;
  margin-bottom: 0.5em;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #d22222;
  box-sizing: border-box;
  border-radius: 6px 0 0 6px;
  padding: 0.5em 0.5em 0.5em 1em;
  min-width: 15em;
  outline: 0;
`;

const InputGroup = styled.div`
  display: flex;
`;

const SubmitButton = styled.button`
  background: #d22222;
  border: 1px solid #d22222;
  box-sizing: border-box;
  border-radius: 0 6px 6px 0;
  color: white;
  padding: 0.5em 0.7em;
  cursor: pointer;
  outline: 0;

  &:hover {
    background-color: ${darken(0.1, '#d22222')};
  }
`;

const PaperAirPlane = styled(Icon)`
  font-size: 1.3em;
`;

const CircleCheck = styled(Icon).attrs({ type: 'CircleCheck' })``;

const Message = styled.p`
  margin-top: 0.5em;
  font-size: 0.8em;

  ${p =>
    p.success &&
    css`
      color: forestgreen;
    `}
 
  ${p =>
    p.error &&
    css`
      color: darkred;
    `}
      
  ${p =>
    p.warning &&
    css`
      color: darkorange;
    `}
`;

function EmailSubscription() {
  const [status, setStatus] = useState({
    success: false,
    warning: false,
    error: false,
  });
  const [email, setEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    const isValid = e.target.elements.email.checkValidity();
    if (!isValid) {
      setStatus({
        success: false,
        warning: true,
        error: false,
      });
    } else {
      setStatus({
        success: false,
        warning: false,
        error: false,
      });

      subscribeService
        .subcribeEmail(email)
        .then(() => {
          setStatus({
            success: true,
            warning: false,
            error: false,
          });
        })
        .catch(() => {
          setStatus({
            success: false,
            warning: false,
            error: true,
          });
        });
    }
  }

  function getMessage() {
    if (status.warning) {
      if (email.length) return <Message warning>Please enter correct email format</Message>;
      return <Message warning>Please enter your email address</Message>;
    }

    if (status.error) return <Message error>Submit your email failed</Message>;

    return null;
  }

  return (
    <Wrapper>
      <form noValidate onSubmit={e => handleSubmit(e)}>
        <Title>GET UPDATED VIA EMAIL</Title>
        <InputGroup>
          <Input
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            required
          />
          <SubmitButton>
            <PaperAirPlane type="PaperAirPlane" />
          </SubmitButton>
        </InputGroup>
        {status.success && (
          <Message success>
            <CircleCheck /> Subscribe email successfully! We will contact you shortly.
          </Message>
        )}
        {getMessage()}
      </form>
    </Wrapper>
  );
}

export default EmailSubscription;
