import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { MainLayout } from './Layout';
import SEO from './SEO';
import Container from './Container';
import { media } from '../utils/MediaResponsive';
import EmailSubscription from './EmailSubscription';
import { findImageByName } from '../utils';

const CourseName = styled.span`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${p => p.theme.brand};
  margin-bottom: 1.5em;

  ${media.tablet`
    font-size: 14px;
    line-height: 20px;

  `}
`;

const ContentContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  padding: 0 10%;
`;

const CourseDate = styled.h1`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5em;

  color: #242424;
`;

const CourseDetail = styled.p`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3em;
  margin-bottom: 2.5em;

  color: #7f7f7f;
`;

const ImageContainer = styled.div`
  flex-basis: 50%;
`;

const Wrapper = styled.div`
  font-size: 1rem;
  display: flex;

  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    flex-flow: column nowrap;

    ${ContentContainer} {
      width: 100%;
      align-items: center;
      padding-top: 3em;
      padding-bottom: 3em;
    }

    ${ImageContainer} {
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    font-size: 0.6em;
  }
`;

const ComingSoon = ({ comingCourseInfo }) => (
  <StaticQuery
    query={graphql`
      query {
        comingSoon: allFile(filter: { relativeDirectory: { eq: "coming-soon" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MainLayout>
        <SEO
          title={`${comingCourseInfo.courseName} - Coming soon`}
          keywords={[`dolonline`, `dolenglish`, `coming soon`, comingCourseInfo.courseName]}
        />
        <Container>
          <Wrapper>
            <ContentContainer>
              <CourseName>{comingCourseInfo.courseName}</CourseName>
              <CourseDate>{comingCourseInfo.courseDate}</CourseDate>
              <CourseDetail>{comingCourseInfo.courseDetail} </CourseDetail>
              <EmailSubscription />
            </ContentContainer>
            <ImageContainer>
              <Img fluid={findImageByName(comingCourseInfo.imageName, data, 'comingSoon', 'fluid')} />
            </ImageContainer>
          </Wrapper>
        </Container>
      </MainLayout>
    )}
  />
);

ComingSoon.propTypes = {
  comingCourseInfo: PropTypes.shape({
    courseName: PropTypes.string.isRequired,
    courseDate: PropTypes.string.isRequired,
    courseDetail: PropTypes.string.isRequired,
    imageName: PropTypes.string.isRequired,
  }).isRequired,
};

export default ComingSoon;
